<template lang='pug'>
Home(
  :aprInitial='apr'
  :downPaymentInitial='downPaymentAmount'
  :downPaymentPercentInitial='downPaymentPercent'
  :priceInitial='price'
  :termInitial='term'
)
</template>

<script>
import { cond, defaultTo, divide, get, gt, identity, multiply, pipe, placeholder, stubTrue } from 'lodash/fp';

import Home from '../components/Home.vue';

import { stringToNumber } from '@thomasphan/vue-components/src/functions/stringToNumber';

const apr =
{
  required: true
};

const downPayment =
{
  required: true
};

const price =
{
  required: true
};

const term =
{
  required: true
};

const downPaymentAmount = function ()
{
  return pipe
  ([
    get('downPaymentSanitized'),
    cond([
      [gt(placeholder, 100), identity],
      [stubTrue, () => this.downPaymentFromDownPaymentPercent],
    ]),
  ])
  (this);
};

const downPaymentFromDownPaymentPercent = function ()
{
  return pipe
  ([
    get('downPaymentSanitized'),
    multiply(this.priceSanitized),
    divide(placeholder, 100),
  ])
  (this);
};

const downPaymentPercent = function ()
{
  return pipe
  ([
    get('downPaymentAmount'),
    divide(placeholder, this.priceSanitized),
    multiply(100),
  ])
  (this);
};

const downPaymentSanitized = function ()
{
  return pipe
  ([
    get('downPayment'),
    this.stringToNumber,
    defaultTo(0),
  ])
  (this);
};

const priceSanitized = function ()
{
  return pipe
  ([
    get('price'),
    this.stringToNumber,
    defaultTo(0),
  ])
  (this);
};

export default
{
  components:
  {
    Home,
  },
  computed:
  {
    downPaymentAmount,
    downPaymentFromDownPaymentPercent,
    downPaymentPercent,
    downPaymentSanitized,
    priceSanitized,
  },
  methods:
  {
    stringToNumber
  },
  props:
  {
    apr,
    downPayment,
    price,
    term,
  },
};
</script>